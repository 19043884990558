/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1j52uag bg--top --full" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --left pl--0" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box swpf--uppercase ls--50" content={"<span style=\"color: white;\">Pizzeria</span>"}>
              </Subtitle>

              <Title className="title-box fs--154 lh--1" content={"<span style=\"color: white;\">Basilico</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--cColor2 swpf--uppercase" href={"#menu"} content={"Tagesmenü"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"einleitung-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pb--10 pt--10" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Traditionelle Pizza sorgfältig<br>nach italienischen Rezepten hergestellt"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1e8csyg --parallax pb--80 pt--80" name={"menu"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/123/img-1_s=2000x_.jpg_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"aktualitäten"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Aktualitäten"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">14.7.2021<br>Wir eröffnen eine neue Filiale<br></span><br>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">14.7.2021<br>Wir eröffnen eine neue Filiale<br></span><br>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Tagesmenü"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Montag, 5/1<br></span><br>1. Schweineroulade, Kartoffelpüree, Gemüsesalat - 100 CZK <br> 2. Pasta mit Hühnerfleisch, Spinat und Sahne - 100 CZK <br> 3. Knoblauch Schweinekoteletts, amerikanische Kartoffeln, Tartar - 100 CZK <br> 4. Gemüsesalat mit Hähnchenstreifen, Dressing, Gebäck - 100 CZK\n\n "}>
              </Text>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Diesntag, 5/2<br></span><br>1. Schweineroulade, Kartoffelpüree, Gemüsesalat - 100 CZK <br> 2. Pasta mit Hühnerfleisch, Spinat und Sahne - 100 CZK <br> 3. Knoblauch Schweinekoteletts, amerikanische Kartoffeln, Tartar - 100 CZK <br> 4. Gemüsesalat mit Hähnchenstreifen, Dressing, Gebäck - 100 CZK\n\n "}>
              </Text>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Mittwoch, 5/3<br></span><br>1. Schweineroulade, Kartoffelpüree, Gemüsesalat - 100 CZK <br> 2. Pasta mit Hühnerfleisch, Spinat und Sahne - 100 CZK <br> 3. Knoblauch Schweinekoteletts, amerikanische Kartoffeln, Tartar - 100 CZK <br> 4. Gemüsesalat mit Hähnchenstreifen, Dressing, Gebäck - 100 CZK\n\n "}>
              </Text>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Donnerstag, 5/4<br></span><br>1. Schweineroulade, Kartoffelpüree, Gemüsesalat - 100 CZK <br> 2. Pasta mit Hühnerfleisch, Spinat und Sahne - 100 CZK <br> 3. Knoblauch Schweinekoteletts, amerikanische Kartoffeln, Tartar - 100 CZK <br> 4. Gemüsesalat mit Hähnchenstreifen, Dressing, Gebäck - 100 CZK\n\n "}>
              </Text>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Freitag, 5/5<br></span><br>1. Schweineroulade, Kartoffelpüree, Gemüsesalat - 100 CZK <br> 2. Pasta mit Hühnerfleisch, Spinat und Sahne - 100 CZK <br> 3. Knoblauch Schweinekoteletts, amerikanische Kartoffeln, Tartar - 100 CZK <br> 4. Gemüsesalat mit Hähnchenstreifen, Dressing, Gebäck - 100 CZK\n\n "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-fkiv95 --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/123/img-2_s=2000x_.jpg_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box title-box--left" content={"<span style=\"color: white;\">Straßenname<br>+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--18 swpf--uppercase ls--50 pl--06" content={"Pizzeria"}>
              </Subtitle>

              <Title className="title-box mt--0" content={"Basilico"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":400}} content={"Bestellung +49 797 811 2X05<br>Buchung +49 797 811 2X05<br><a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}